import axios from 'axios';
// config
import { HOST_API_KEY } from '../config-global';
import toast from 'react-hot-toast';
import { RequestCouldntBeCompletedToasterMessage } from './toasterMessage';

// ----------------------------------------------------------------------

const axiosClient = axios.create({
  baseURL: HOST_API_KEY,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    "Accept-Language": document.getElementsByTagName("html")[0].getAttribute("lang")
  }
});

axiosClient.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axiosClient.defaults.xsrfCookieName = 'csrftoken';
axiosClient.defaults.withCredentials = true;
// interceptor exemple !
axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const res = error.response;

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    console.error(`Looks like there was a problem. Status Code: ${res.status}`);

    try {
      console.error(res.data)
    }catch (e) {
      console.log('No data')
    }



    if (res.status === 500) {
      toast.error(RequestCouldntBeCompletedToasterMessage(), {
        duration: 4000,
        position: 'top-right'
      });
    }

    if (res.status == 403) {
      if (res.data.detail) {
        toast.error(res.data.detail, {
          duration: 4000,
          position: 'top-right'
        });
      }
    }

    return await Promise.reject(error);
  }
);

export default axiosClient;
