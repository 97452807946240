import { Suspense, lazy, ElementType } from 'react';
import GlobalLoader from '../components/globalLoader';
// components

// ----------------------------------------------------------------------

// eslint-disable-next-line react/display-name
const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<GlobalLoader />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const DashboardPage = Loadable(
  lazy(async () => await import('../pages/dashboard/DashboardPage'))
);
export const AllAnalytics = Loadable(
  lazy(async () => await import('../pages/allAnalytics/allAnalytics'))
);
export const ShortAnalytics = Loadable(
  lazy(async () => await import('../pages/shortAnalytics'))
);

export const PostsList = Loadable(
  lazy(async () => await import('../pages/postsList'))
);

export const FacebookAnalyticsPage = Loadable(
  lazy(async () => await import('../pages/platformAnalytics/facebook'))
);

export const InstagramAnalyticsPage = Loadable(
  lazy(async () => await import('../pages/platformAnalytics/instagram'))
);
export const GoogleAnalyticsPage = Loadable(
  lazy(async () => await import('../pages/platformAnalytics/google'))
);

export const LinkedinPersonalAccountAnalyticsPage = Loadable(
  lazy(
    async () =>
      await import('../pages/platformAnalytics/linkedin_personal_account')
  )
);
export const LinkedinBusinessPageAnalytics = Loadable(
  lazy(
    async () =>
      await import('../pages/platformAnalytics/linkedin_business_page')
  )
);

export const TikTokBusinessPageAnalytics = Loadable(
  lazy(
    async () => await import('../pages/platformAnalytics/tiktok_business_page')
  )
);

export const TwitterAnalyticsPage = Loadable(
  lazy(async () => await import('../pages/platformAnalytics/twitter'))
);
export const YoutubeAnalyticsPage = Loadable(
  lazy(async () => await import('../pages/platformAnalytics/youtube'))
);
export const DetailsAnalyticsPage = Loadable(
  lazy(async () => await import('../pages/detailsAnalytics'))
);

export const TeamManagement = Loadable(
  lazy(async () => await import('../pages/accounts/team/teamManagement'))
);
export const CreateInvite = Loadable(
  lazy(async () => await import('../pages/accounts/team/invite/create'))
);
export const EditUser = Loadable(
  lazy(async () => await import('../pages/accounts/team/edit'))
);
export const EditInvite = Loadable(
  lazy(async () => await import('../pages/accounts/team/invite/edit'))
);
export const FacebookTokenVerifier = Loadable(
  lazy(
    async () => await import('../pages/platforms/facebook_/verifyAccessToken')
  )
);
export const ConnectedSocialMediaAccountsListView = Loadable(
  lazy(
    async () =>
      await import(
        '../pages/platforms/accounts/connected_social_media_accounts/connected_social_media_accounts/'
      )
  )
);
export const ConnectedProfilesByConnectedSocialMediaAccountsListView = Loadable(
  lazy(
    async () =>
      await import(
        '../pages/platforms/accounts/connected_social_media_accounts/connected_profiles_overview/'
      )
  )
);
export const PostPublicationLog = Loadable(
  lazy(async () => await import('../pages/Posts/PostPublicationLog'))
);
export const LomavisCreatorPage = Loadable(
  lazy(async () => await import('../pages/lomavisCreator'))
);

export const Communication = Loadable(
  lazy(async () => await import('../pages/communication'))
);
export const ExternalContentCollector = Loadable(
  lazy(async () => await import('../pages/externalContent'))
);

export const PostIdeasList = Loadable(
  lazy(async () => await import('../pages/Posts/postIdeas/postIdeasList'))
);

export const SettingsBrandingTemplateBrandingPage = Loadable(
  lazy(
    async () =>
      await import('../pages/settings/branding/template_branding/index')
  )
);

export const OnboardingBrandingTemplate = Loadable(
  lazy(async () => await import('../pages/brandOnboarding/index'))
);
