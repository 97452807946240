function path(root: string, sublink: string): string {
  return `${root}${sublink}`;
}

const ROOT_PATH = '';

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  dashboard: path(ROOT_PATH, '/locations/:locationslug/dashboard'),
  allAnalytics: path(ROOT_PATH, '/locations/:locationslug/analytics/all'),
  facebookPlatformAnalytics: path(
    ROOT_PATH,
    '/locations/:locationslug/analytics/p/facebook'
  ),
  instagramPlatformAnalytics: path(
    ROOT_PATH,
    '/locations/:locationslug/analytics/p/instagram'
  ),
  googlePlatformAnalytics: path(
    ROOT_PATH,
    '/locations/:locationslug/analytics/p/google'
  ),
  twitterPlatformAnalytics: path(
    ROOT_PATH,
    '/locations/:locationslug/analytics/p/twitter'
  ),
  youtubePlatformAnalytics: path(
    ROOT_PATH,
    '/locations/:locationslug/analytics/p/youtube'
  ),
  linkedinPersonalAccountPlatformAnalytics: path(
    ROOT_PATH,
    '/locations/:locationslug/analytics/p/linkedin_personal_account'
  ),
  LinkedinBusinessPageAnalytics: path(
    ROOT_PATH,
    '/locations/:locationslug/analytics/p/linkedin_business_page'
  ),
  TikTokBusinessPageAnalytics: path(
    ROOT_PATH,
    '/locations/:locationslug/analytics/p/tiktok_business_page'
  ),
  pinterestAccountPlatformAnalytics: path(
    ROOT_PATH,
    '/locations/:locationslug/analytics/p/pinterest'
  ),

  posts_list: path(ROOT_PATH, '/locations/:locationslug/posts/status/:status'),
  shortAnalytics: path(ROOT_PATH, '/locations/:locationslug/posts/analytics'),

  clientPortalShortAnalytics: path(
    ROOT_PATH,
    '/connect/:locationslug/analytics'
  ),
  DetailsAnalytics: path(
    ROOT_PATH,
    '/locations/:locationslug/posts/:postuuid/analytics'
  ),
  PostPublicationLogForPostInLocation: path(
    ROOT_PATH,
    '/locations/:locationslug/posts/:postuuid/post-publication-log/'
  ),
  PostPublicationLogForMultipost: path(
    ROOT_PATH,
    'locations/centralized/posts/p/:postuuid/post-publication-log/'
  ),

  team_management: path(ROOT_PATH, '/accounts/team'),
  create_invite: path(ROOT_PATH, '/accounts/team/invite/create'),
  edit_user: path(ROOT_PATH, '/accounts/team/member/:uuid/edit'),
  edit_invite: path(ROOT_PATH, '/accounts/team/invite/:uuid/edit'),
  platforms_facebook_verify_access_token: path(
    ROOT_PATH,
    '/platform/facebook/verify_access_token/'
  ),
  locations_facebook_verify_access_token: path(
    ROOT_PATH,
    '/locations/:locationslug/platform/facebook/verify-fb-access-token/'
  ),
  platforms_connected_social_media_accounts: path(
    ROOT_PATH,
    '/accounts/connected-social-media-accounts/'
  ),
  platforms_connected_social_media_accounts_connected_profiles: path(
    ROOT_PATH,
    '/accounts/connected-social-media-accounts-connected-profiles/'
  ),
  locationLomavisCreator: path(
    ROOT_PATH,
    '/locations/:locationslug/posts/create/'
  ),
  lomavisCreatorPostEdit: path(
    ROOT_PATH,
    '/locations/:locationslug/posts/:uuid/edit/'
  ),
  cloudLomavisCreator: path(ROOT_PATH, '/clouds/:cloudslug/posts/create/'),
  cloudCreatorPostEdit: path(ROOT_PATH, '/clouds/:cloudslug/posts/:uuid/edit/'),
  multipostLomavisCreator: path(ROOT_PATH, 'locations/create_post/'),
  multipostLomavisCreatorEdit: path(
    ROOT_PATH,
    'locations/centralized/posts/p/:uuid/edit/'
  ),
  communication: path(
    ROOT_PATH,
    '/communication-center/chat/:conversationUUid'
  ),
  location_communication: path(
    ROOT_PATH,
    '/locations/:locationslug/communication/chat/:conversationUUid'
  ),
  communication_without_uuid: path(ROOT_PATH, '/communication-center/chat'),
  location_communication_without_uuid: path(
    ROOT_PATH,
    '/locations/:locationslug/communication/chat'
  ),
  external_content_collect: path(
    ROOT_PATH,
    '/clouds/:cloudSlug/posts/collect/:cloudContentAccessUuid'
  ),
  post_ideas_list: path(ROOT_PATH, '/clouds/:cloudslug/post-ideas'),
  settings_branding_template_branding: path(
    ROOT_PATH,
    '/locations/:locationslug/settings/branding_settings/'
  ),
  onboarding_branding_template: path(
    ROOT_PATH,
    '/locations/:locationslug/cloud/posts/'
  )
};
