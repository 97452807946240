import { Navigate, useRoutes } from 'react-router-dom';

// config
//
import {
  DashboardPage,
  AllAnalytics,
  PostsList,
  ShortAnalytics,
  FacebookAnalyticsPage,
  InstagramAnalyticsPage,
  GoogleAnalyticsPage,
  TwitterAnalyticsPage,
  LinkedinPersonalAccountAnalyticsPage,
  LinkedinBusinessPageAnalytics,
  TeamManagement,
  CreateInvite,
  EditUser,
  DetailsAnalyticsPage,
  YoutubeAnalyticsPage,
  FacebookTokenVerifier,
  ConnectedSocialMediaAccountsListView,
  PostPublicationLog,
  EditInvite,
  Communication,
  LomavisCreatorPage,
  ExternalContentCollector,
  PostIdeasList,
  SettingsBrandingTemplateBrandingPage,
  OnboardingBrandingTemplate
} from './elements';

import { PATH_PAGE } from './paths';
import TikTokBusinessPageAnalyticsPage from '../pages/platformAnalytics/tiktok_business_page';
import ConnectedProfilesByConnectedSocialMediaAccountsListView from '../pages/platforms/accounts/connected_social_media_accounts/connected_profiles_overview';
import PinterestAnalyticsPage from '../pages/platformAnalytics/pinterest_acount';
// ----------------------------------------------------------------------

export default function Router(): any {
  return useRoutes([
    // Dashboard
    {
      path: PATH_PAGE.dashboard,
      element: <DashboardPage />
    },
    {
      path: PATH_PAGE.allAnalytics,
      element: <AllAnalytics />
    },
    {
      path: PATH_PAGE.facebookPlatformAnalytics,
      element: <FacebookAnalyticsPage />
    },
    {
      path: PATH_PAGE.instagramPlatformAnalytics,
      element: <InstagramAnalyticsPage />
    },
    {
      path: PATH_PAGE.googlePlatformAnalytics,
      element: <GoogleAnalyticsPage />
    },
    {
      path: PATH_PAGE.linkedinPersonalAccountPlatformAnalytics,
      element: <LinkedinPersonalAccountAnalyticsPage />
    },
    {
      path: PATH_PAGE.LinkedinBusinessPageAnalytics,
      element: <LinkedinBusinessPageAnalytics />
    },

    {
      path: PATH_PAGE.twitterPlatformAnalytics,
      element: <TwitterAnalyticsPage />
    },
    {
      path: PATH_PAGE.youtubePlatformAnalytics,
      element: <YoutubeAnalyticsPage />
    },
    {
      path: PATH_PAGE.TikTokBusinessPageAnalytics,
      element: <TikTokBusinessPageAnalyticsPage />
    },
    {
      path: PATH_PAGE.pinterestAccountPlatformAnalytics,
      element: <PinterestAnalyticsPage />
    },
    {
      path: PATH_PAGE.posts_list,
      element: <PostsList />
    },
    {
      path: PATH_PAGE.shortAnalytics,
      element: <ShortAnalytics />
    },
    {
      path: PATH_PAGE.clientPortalShortAnalytics,
      element: <ShortAnalytics />
    },
    {
      path: PATH_PAGE.DetailsAnalytics,
      element: <DetailsAnalyticsPage />
    },
    {
      path: PATH_PAGE.PostPublicationLogForPostInLocation,
      element: <PostPublicationLog />
    },
    {
      path: PATH_PAGE.PostPublicationLogForMultipost,
      element: <PostPublicationLog />
    },
    {
      path: PATH_PAGE.team_management,
      element: <TeamManagement />
    },
    { path: PATH_PAGE.create_invite, element: <CreateInvite /> },
    {
      path: PATH_PAGE.edit_user,
      element: <EditUser />
    },
    {
      path: PATH_PAGE.edit_invite,
      element: <EditInvite />
    },
    {
      path: PATH_PAGE.platforms_facebook_verify_access_token,
      element: <FacebookTokenVerifier />
    },
    {
      path: PATH_PAGE.locations_facebook_verify_access_token,
      element: <FacebookTokenVerifier />
    },
    {
      path: PATH_PAGE.platforms_connected_social_media_accounts,
      element: <ConnectedSocialMediaAccountsListView />
    },
    {
      path: PATH_PAGE.platforms_connected_social_media_accounts_connected_profiles,
      element: <ConnectedProfilesByConnectedSocialMediaAccountsListView />
    },
    {
      path: PATH_PAGE.locationLomavisCreator,
      element: <LomavisCreatorPage />
    },
    { path: PATH_PAGE.lomavisCreatorPostEdit, element: <LomavisCreatorPage /> },
    {
      path: PATH_PAGE.cloudLomavisCreator,
      element: <LomavisCreatorPage />
    },
    { path: PATH_PAGE.cloudCreatorPostEdit, element: <LomavisCreatorPage /> },
    {
      path: PATH_PAGE.multipostLomavisCreator,
      element: <LomavisCreatorPage />
    },
    {
      path: PATH_PAGE.multipostLomavisCreatorEdit,
      element: <LomavisCreatorPage />
    },
    {
      path: PATH_PAGE.external_content_collect,
      element: <ExternalContentCollector />
    },
    {
      path: PATH_PAGE.communication,
      element: <Communication />
    },
    {
      path: PATH_PAGE.location_communication,
      element: <Communication />
    },
    {
      path: PATH_PAGE.communication_without_uuid,
      element: <Communication />
    },
    {
      path: PATH_PAGE.location_communication_without_uuid,
      element: <Communication />
    },
    {
      path: PATH_PAGE.post_ideas_list,
      element: <PostIdeasList />
    },
    {
      path: PATH_PAGE.settings_branding_template_branding,
      element: <SettingsBrandingTemplateBrandingPage />
    },
    {
      path: PATH_PAGE.onboarding_branding_template,
      element: <OnboardingBrandingTemplate />
    },
    { path: '*', element: <Navigate to="/frontend-react/test/" replace /> }
  ]);
}
