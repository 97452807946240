/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resolve } from '../../../api/endPoints';

import axiosClient from '../../../utils/axios';
import { update } from 'lodash';
export interface WhatsappTemplateProps {
  uuid: string;
  openTemplateModal: boolean;
  data: {
    name: string;
    language: string;
    category: string;
    components: ComponentProps[];
  } | null;
}
export interface ComponentProps {
  type: 'HEADER' | 'BODY' | 'FOOTER' | 'BUTTONS';
  format?: 'TEXT'; // Only applicable for HEADER
  text?: string;
  example?: any;
  buttons?: any;
}

const initialState: any = {
  sample_template_data: [],
  template_data: [],
  loading_template: false,
  modal_close_event: null
};

const slice = createSlice({
  name: 'WhatsappTemplateBuilder',
  initialState,
  reducers: {
    toggleTemplateModal: (state, action: PayloadAction<boolean>) => {
      state.openTemplateModal = action.payload;
    },
    updateTemplateData: (state, action: PayloadAction<any>) => {
      state.template_data = action.payload;
    },
    updateloadingState: (state, action: PayloadAction<boolean>) => {
      state.loading_template = action.payload;
    },
    triggerTemplateModalClose: (state) => {
      state.modal_close_event = Date();
    }
  }
});

export const {
  toggleTemplateModal,
  updateTemplateData,
  updateloadingState,
  triggerTemplateModalClose
} = slice.actions;

export default slice.reducer;

export const getWhatsappTemplates =
  (locationUUID) => async (dispatch: any, getState: any) => {
    function sortItemsByTypeAndName(items) {
      return items.sort((a, b) => {
        if (a.type === b.type) {
          return a.name.localeCompare(b.name);
        }
        return a.type.localeCompare(b.type);
      });
    }

    const url = resolve(
      'get_whatsapp_templates',
      {},
      { location__uuid: locationUUID }
    );

    dispatch(slice.actions.updateloadingState(true)); // Start loading

    try {
      // Fetch initial data
      let results: any[] = [];
      const res = await axiosClient.get(url);

      const resultsData = res.data.results.map((item: any) => item.data);
      results.push(...resultsData);

      // Fetch additional pages if available
      let next = res.data?.next;
      while (next) {
        const pageRes = await axiosClient.get(next);
        const pageResultsData = pageRes.data.results.map(
          (item: any) => item.data
        );
        results.push(...pageResultsData);
        next = pageRes.data?.next;
      }

      // Sort items by type and name
      results.sort(
        (a, b) => a.category.localeCompare(b.category) || b.name - a.name
      );

      dispatch(slice.actions.updateTemplateData(results));
    } catch (error: any) {
      console.error('Error fetching WhatsApp templates:', error);

      // Dispatch an error action if needed
    } finally {
      dispatch(slice.actions.updateloadingState(false)); // Stop loading
    }
  };

export const sendWhasappTemplateMessage =
  (
    conversationUuid: string,
    messageUuid: string = null,
    isInternaNote: boolean = false,
    successCallback: any,
    additionalData: any
  ) =>
  async (dispatch: any, getState: any) => {
    const url = resolve('communication_messages', {}, {});
    try {
      const res = (await axiosClient.post(url, {
        parent_message_uuid: messageUuid,
        conversation_uuid: conversationUuid,
        is_internal_note: isInternaNote,
        message_content_quill: JSON.stringify({
          ops: [{ insert: 'template' }]
        }),
        additional_data: additionalData
      })) as any;
      // get json response
      successCallback(res?.data?.uuid);
    } catch (err: any) {
      console.log('submit template', err);
    }
  };
